if (!window.doneOnce) {
    window.doneOnce = {}
}

export default function once(name) {
    if (!window.doneOnce[name]) {
        window.doneOnce[name] = true
        return true
    }
    return false
}

import once from "@/libs/once.js"
import { reactive } from "vue"

function createEventbus() {
    const eventBus = reactive({
        events: {},
        $on(event, callback) {
            if (!this.events[event]) {
                this.events[event] = []
            }
            this.events[event].push(callback)
        },
        $emit(event, payload, index, ...args) {
            if (this.events[event]) {
                this.events[event].forEach((callback) => callback(payload, index, ...args))
            }
        },
    })

    return (window.eventBus = window.eventBus || eventBus)
}

const togglePulse = (element) => {
    if (element) {
        element.classList.remove("pulse")
        void element.offsetWidth
        element.classList.add("pulse")
    }
}

const toggleClass = (token, force, element) => {
    element?.classList.toggle(token, force)
}

const handleErrors = (errors) => {
    let emitType = ""
    let params = []
    const userActivityStatus = false

    if (errors.downloadLimitExceeded) {
        emitType = "download_limit_exceeded"
        params = [errors.downloadLimitExceeded]
    } else if (errors.downloadNotAllowed) {
        emitType = "download_not_allowed"
        params = [errors.downloadNotAllowed, userActivityStatus]

        if (errors.contentSubType === "ebook") {
            emitType = "ebook_download_not_allowed"
        }
    } else if (errors.tutorialNotAllowed) {
        emitType = "tutorial_not_possible"
        params = [errors.tutorialNotAllowed]
    } else if (errors.pdfTutorialNotAllowed) {
        emitType = "pdf_tutorial_not_possible"
        params = [errors.pdfTutorialNotAllowed]
    } else if (errors.streamingNotAllowed) {
        emitType = "streaming_not_possible"
        params = [errors.streamingNotAllowed]
    } else if (errors.workingFileDownloadNotAllowed) {
        emitType = "working_file_download_not_allowed"
        params = [errors.workingFileDownloadNotAllowed]
    } else if (errors.noAccount) {
        emitType = "download_not_allowed"
        params = [errors.noAccount]
    }

    return { emitType, params, hasUser: !errors.noAccount, testLicenceCanceled: !!errors.testLicence }
}

export async function checkUseContent(id, type = false, doShowPopup = true) {
    const result = await window.globalJs.axios.get(`/canuse/${id}${type && isNaN(type) ? "/" + type : ""}`)
    const responseStatus = result?.data?.response_status

    if (responseStatus === "ok") {
        return true
    } else {
        if (doShowPopup) {
            const { emitType, params, hasUser, testLicenceCanceled } = handleErrors(result?.data?.errors || {})
            if (emitType) {
                window.eventBus.$emit(emitType, hasUser, testLicenceCanceled, ...params)
            }
        }

        return false
    }
}

const handleResponse = async (type, id, urlSuffix, tokenClass) => {
    const elements = [
        document.getElementById(`${type}-id-${id}`),
        document.getElementById(`mobile-${type}-id-${id}`),
        ...document.querySelectorAll(`.add-icon-${type}-${id}`),
    ].filter(Boolean)

    elements.forEach(togglePulse)

    const result = await window.globalJs.axios.post(`/${type}/${id}/${urlSuffix}`, { package: id })
    const isSuccess = result.data > 0

    elements.forEach((element) => {
        toggleClass(tokenClass, isSuccess, element)
        if (element.dataset.remove && !isSuccess) {
            const card = element.closest(".card")
            if (card) {
                card.classList.add("fade-out")
                card.onanimationend = () => card.remove()
            }
        }
        const newTitle = element?.dataset[`title${isSuccess ? "Active" : "Inactive"}`]
        if (newTitle) {
            element.setAttribute("title", newTitle)
        }
    })
}

export function apply_is_added() {
    if (once("apply_is_added")) {
        createEventbus().$on("apply_is_added", (type, id) => handleResponse(type, id, "added", "green"))
    }
}

export function apply_is_fav() {
    if (once("apply_is_fav")) {
        createEventbus().$on("apply_is_fav", (type, id) => handleResponse(type, id, "fav", "red"))
    }
}

const handleUrlEvent = async (url, event, newTab = false) => {
    event?.preventDefault()
    const matchesId = url.match(/(use\/|play-)(\d+)\/?(.*)$/)

    if (matchesId?.[2]) {
        const canUse = await checkUseContent(matchesId[2], matchesId[3] !== "" ? matchesId[3] : false)
        if (canUse && !newTab) {
            window.location = url
        }
    }
}

window.useContent = (url, event) => handleUrlEvent(url, event)
window.useSingleContent = async (url, event) => {
    event && event.preventDefault()
    window.location = url
}
window.useContentNewTab = (url, event) => handleUrlEvent(url, event, true)

const preventDefaultEmit = (event, emitType) => {
    event?.preventDefault()
    window.eventBus.$emit(emitType, false)
}

window.useTextTutorials = (event) => preventDefaultEmit(event, "tutorial_not_possible")
window.usePdfTutorials = (event) => preventDefaultEmit(event, "pdf_tutorial_not_possible")
